<template>
  <v-form>
    <v-row>

      <v-col cols="2">
        <v-checkbox
          outlined
          dense
          v-model="record.status"
          label="Active"
        ></v-checkbox>
      </v-col>

      <v-col cols="2">
        <v-checkbox
          outlined
          dense
          v-model="record.SUPERUSER"
          label="Superuser"
        ></v-checkbox>
      </v-col>

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          v-model="record.name"
          label="Full Name"
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field
          outlined
          dense
          :name="`username_${Math.random()}`"
          v-model="record.email"
          label="Email"
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-text-field
          outlined
          dense
          autocomplete="nope"
          v-model="password"
          label="Password"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          v-model="record.phone_number"
          type="phone"
          label="Phone Number"
        ></v-text-field>
      </v-col>

      <v-col cols="3">
        <v-autocomplete
          outlined
          dense
          v-model="record.gate_id"
          :items="gates"
          item-text="Name"
          item-value="id"
          label="Gate"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="accent"
          @click="sendData"
          :loading="$store.state.loader"
        >
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    itemsData: {
      type: Array,
    },
    initial: {
      type: Object,
    },
    docCreate: {
      type: Boolean,
    },
  },

  data() {
    return {
      record: {},
      loader: false,
      signatureAttachment: null,
      search: "",
      userGroups: [],
      singleSelect: true,
      searchInput: null,
      searchInputB: null,
      selectionType: "leaf",
      employee: {},
      trueValue: true,
      userDefaults: [],
      departments: [],
      branches: [],
      gates: [],
      DfltsGroup: {},
      SUPERUSER: true,
      password: null,
      dialog: false,
      useLocalSearch: false,
      status: [
        { name: "Pending", value: "P" },
        { name: "Confirmed", value: "C" },
      ],
      roles: [
        { name: "System Manager", value: "Sys" },
        { name: "Managers", value: "Manager" },
        { name: "Administrator", value: "Admin" },
      ],
      headers: [
        { text: "Employee", value: "employee.SlpName" },
        { text: "Warehouse", value: "warehouse.WhsName" },
      ],
      userTypes: [
        { name: "Approver", value: "A" },
        { name: "API User", value: "AU" },
        { name: "Normal User", value: "NU" },
      ],
    };
  },
  watch: {
    initial: {
      handler: "setInitial",
      immediate: true,
    },
  },
  methods: {
    setInitial(val) {
      console.log(val, "Initial");
      this.record = { ...val };
    },
    selectItem(data) {
      this.record = data;
      this.dialog = false;
    },
    setStartDate(date) {
      this.record.StartDate = date;
    },
    setEndDate(date) {
      this.record.EndDate = date;
    },
    sendData() {
        // console.log(this.record);
      this.record.licence = "2233";
      this.record.password = this.password ? this.password : null;
      this.$store.commit("loader", true);
      this.$emit("data", this.record);
    },

    getGates() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/gates`)
        .then((res) => {
          self.gates = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getGates();
  },
};
</script>

<style lang="scss" scoped>
</style>